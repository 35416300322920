<template>
    <span>
        <span class="open-pdf" @click="pdfOpened = true">
            <slot>
                Соглашения
            </slot>
        </span>
      <client-only>
        <Teleport to="body">
            <div class="pdf" v-if="pdfOpened" @click="closePdf">
              <div class="pdf-container">
                  <div @click="pdfOpened = false">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="3.75732"
                      y="2.34306"
                      width="14"
                      height="2"
                      rx="1"
                      transform="rotate(45 3.75732 2.34306)"
                      fill="currentColor"
                    />
                    <rect
                      x="2.34326"
                      y="12.2426"
                      width="14"
                      height="2"
                      rx="1"
                      transform="rotate(-45 2.34326 12.2426)"
                      fill="currentColor"
                    />
                  </svg>
                  </div>
                  <iframe v-if="pdfOpened" ref="pdfViewer" :src="`/oferta.pdf`" style="width: 100%; height: 100%"></iframe>
              </div>
            </div>
        </Teleport>
        </client-only>
    </span>
</template>

<script>
import Popup from "~/ui/Popup/Popup.vue";
import Teleport from 'vue2-teleport'


export default {
  components: {Popup, Teleport},
  data () {
    return {
      pdfOpened: false
    }
  },

  methods: {
    closePdf (event) {
      if (event.target.classList[0].includes('pdf')) {
        this.pdfOpened = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
    .open-pdf {
        color: var(--primary-color);
        cursor: pointer;
    }

    .pdf {
      z-index: 1000000;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .pdf-container {
      position: relative;
      background-color: #fff;
      border-radius: 8px;
      padding: 40px;
      width: 900px;
      height: 100vh;

      div {
        position: absolute;
        top: 10px;
        cursor: pointer;
        right: 20px;
        width: 16px;
        height: 16px;
      }
    }

    @media (max-width: 767px) {
        .pdf-container {
            padding: 20px;
            div {
              top: 3px !important;
              right: 7px !important;
            }
        }
    }
</style>
