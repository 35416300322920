
import DocPassport from '@pp-frontend/shared-packages/classes/doc-value/DocPassport'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import DocInn from '@pp-frontend/shared-packages/classes/doc-value/DocInn'
import DocUin from '@pp-frontend/shared-packages/classes/doc-value/DocUin'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import { Component } from 'nuxt-property-decorator'

@Component
// @ts-ignore
export default class MyMixin extends SearchFormAbstract {
    docTypeList:any = [
      {
        text: 'По паспорту',
        name: 'passport',
        value: new DocPassport(),
        fieldId: FieldsId.PASPORT
      },
      {
        text: 'По ИНН',
        name: 'inn',
        value: new DocInn(),
        fieldId: FieldsId.INN
      },
      {
        text: 'По квитанции',
        name: 'doc',
        value: new DocUin(),
        fieldId: FieldsId.UIN
      }
    ]

    innNotFound: boolean = false
    isLoad: boolean = false
    isError: string = ''
    errorDocVal: string = ''
    currentCheck: string = 'passport'
    currentCheckTitle: string = 'По паспорту'
    isOpen: boolean = false
    selectedType: any
    error: boolean = false
    form: object = {}
    loading: boolean = false
    openInnPopup: boolean = false

    get getCurrentCheckTitle () {
      return this.docTypeList.find((element: any) => element.name = this.currentCheck).text
    }

    get formData () {
      if (!this.selectedType) {
        return {}
      }
      return this.selectedType.value || {}
    }

    getInn (val) {
      // @ts-ignore
      this.$v.formData.document_value.$model = val
      this.openInnPopup = false
    }
    // @ts-ignore
    async submit () {
      // @ts-ignore
      if (!this.$v.$touch() && this.$v.$anyError) {
        return
      }
      this.loading = true
      const data = this.formData
      const documentType = this.formData.docType()
      if (documentType === 'passport') {
        const data = await this.$axios.post(process.env.API_ENDPOINT + 'fns/inn-by-passport', {
          document_type: this.formData.docType(),
          document_value: this.formData.document_value.document_value,
          owner_name: this.formData.document_value.fio,
          additional_fields: {
            birth_date: this.formData.document_value.birth_date.split('.').reverse().join('-')
          }
        }).then((response) => {
          this.loading = false
          this.$router.push({
            path: '/search/fns',
            query: (Object.assign({
              document_type: 'inn',
              document_value: response.data.document_value
            }))
          })
        }).catch(() => {
          this.error = true
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = false
        this.$router.push({
          path: '/search/fns',
          query: (Object.assign({
            document_type: documentType
          }, data) as any)
        })
      }
      return Promise.resolve()
    }

    constructor () {
      super()
      this.selectedType = this.docTypeList[0]
    }

    changeCheck (val: any) {
      this.currentCheck = val.name
      this.currentCheckTitle = val.text
      this.selectedType = this.docTypeList.find(el => el.name === val.name)
      this.error = false
      this.$v.formData.$reset()
    }

    toggleDropdown () {
      this.isOpen = !this.isOpen
    }

    selectItem (val: any) {
      this.isOpen = false
      this.currentCheck = val.name
      this.currentCheckTitle = val.text
      this.selectedType = this.docTypeList.find(el => el.name === val.name)
      this.error = false
      this.$v.formData.$reset()
    }
}
