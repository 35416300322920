
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { mask } from 'vue-the-mask'
import Input from '~/components/ui/Input.vue'

@Component({
  components: { Input },
  directives: {
    mask
  }
})
export default class InputDate extends Vue {
  @Prop({ type: String, default: '' }) value!: string
}
